import { forwardRef, InputHTMLAttributes, useRef, useEffect } from "react"
import { BaseFieldProps } from "types/core/formTypes"
import InputLabel from "components/inputs/InputLabel"
import InputError from "components/inputs/InputError"
import Autocomplete from "react-google-autocomplete"

interface Props
  extends BaseFieldProps,
    Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange?: (value: any) => void
}

const GooglePlacesInput = forwardRef<HTMLInputElement, Props>(
  ({ label, error, onChange, value: defaultValue }) => {
    const inputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.setAttribute("autocomplete", "off")
        inputRef.current.setAttribute("name", "fake-name")
        inputRef.current.setAttribute("id", "fake-id")

        if (!inputRef.current.value && defaultValue) {
          inputRef.current.value = defaultValue as string
        }
      }
    }, [])

    const handleValueChange = (
      value: string | google.maps.places.PlaceResult,
    ) => {
      if (typeof value === "string" && inputRef.current) {
        const inputEvent = new Event("input", {
          bubbles: true,
          cancelable: true,
        })
        inputRef.current.dispatchEvent(inputEvent)
      }

      if (onChange) {
        onChange({ place: value, inputRef })
      }
    }

    return (
      <div>
        <InputLabel>{label}</InputLabel>
        <Autocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
          onPlaceSelected={(place) => handleValueChange(place)}
          onChange={(event) => {
            const newValue = (event.target as HTMLInputElement).value
            handleValueChange(newValue)
          }}
          options={{
            types: ["address"],
            componentRestrictions: { country: "us" },
          }}
          className="block w-full p-4 border border-silver rounded-md"
          ref={inputRef}
        />
        <InputError>{error}</InputError>
      </div>
    )
  },
)

export default GooglePlacesInput
