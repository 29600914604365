import { FormFieldI } from "types/core/formTypes"
import { buildYupSchema } from "helpers/core/formHelpers"
import { FormFieldType } from "constants/core/formConstants"
import * as yup from "yup"
import { UseFormSetValue } from "react-hook-form/dist/types/form"
import { stateOptions } from "constants/core/stateConstants"
import { getAddressComponent } from "utils/addressUtils"

export const addressFields = (
  setValue?: UseFormSetValue<any>,
): FormFieldI[] => [
  {
    type: FormFieldType.TEXT,
    name: "nameFirst",
    label: "First name",
    placeholder: "First name",
    rules: yup.string().required("First name is a required field"),
  },
  {
    type: FormFieldType.TEXT,
    name: "nameLast",
    label: "Last name",
    placeholder: "Last name",
    rules: yup.string().required("Last name is a required field"),
  },
  {
    type: FormFieldType.SELECT,
    name: "addressCountry",
    label: "Country",
    placeholder: "Country",
    rules: yup.string().required("Country is required"),
    options: [
      {
        value: "US",
        label: "United States of America",
      },
    ],
  },
  {
    type: FormFieldType.GOOGLE_PLACES,
    name: "addressLine1",
    label: "Street address",
    placeholder: "Street address",
    rules: yup.string().required("Street address is required"),
    onChange: ({
      place,
      inputRef,
    }: {
      place: google.maps.places.PlaceResult
      inputRef: React.RefObject<HTMLInputElement>
    }) => {
      if (!setValue) {
        return
      }

      if (typeof place === "string") {
        setValue("addressLine1", place, { shouldDirty: true })
      }

      if (!place?.address_components) {
        return
      }

      const addressComponents = place.address_components

      const addressCity =
        getAddressComponent(addressComponents, "locality") ||
        getAddressComponent(addressComponents, "administrative_area_level_2")
      const addressRegion = getAddressComponent(
        addressComponents,
        "administrative_area_level_1",
        false,
      )
      const addressPostalCode = getAddressComponent(
        addressComponents,
        "postal_code",
      )
      const addressCountry = getAddressComponent(
        addressComponents,
        "country",
        false,
      )
      const streetNumber =
        getAddressComponent(addressComponents, "street_number") || ""
      const route = getAddressComponent(addressComponents, "route") || ""
      const formattedStreetAddress = `${streetNumber} ${route}`.trim()

      setValue("addressCity", addressCity)
      setValue("addressRegion", addressRegion)
      setValue("addressPostalCode", addressPostalCode)
      setValue("addressCountry", addressCountry)
      setValue("addressLine1", formattedStreetAddress, { shouldDirty: true })

      if (inputRef.current) {
        inputRef.current.value = formattedStreetAddress
      }
    },
  },
  {
    type: FormFieldType.TEXT,
    name: "addressCity",
    label: "City",
    placeholder: "City",
    rules: yup.string().required("City is a required field"),
  },
  {
    type: FormFieldType.SELECT,
    name: "addressRegion",
    label: "State",
    placeholder: "State",
    options: stateOptions,
    rules: yup.string().required("State is required"),
  },
  {
    type: FormFieldType.NUMBER,
    name: "addressPostalCode",
    label: "Postal code",
    placeholder: "Postal code",
    rules: yup
      .string()
      .required("Postal code is a required field")
      .matches(
        /^\d{5}(-\d{4})?$/,
        "Postal code must be in the format 12345 or 12345-6789",
      ),
  },
]

export const addressYupSchema = buildYupSchema(addressFields())
